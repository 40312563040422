import React from 'react'
import { Player,BigPlayButton,PosterImage  } from 'video-react'

export const Video = () => {
    console.log()
    return (
        <div className='flex space-y-10  flex-col justify-center items-center bg-white px-4 py-10 border-2 rounded-lg w-[90%] mx-auto'>
            <div className='sm:text-4xl text-2xl font-bold underline text-[#D00202] text-opacity-95 text-center'>HackManthan 2022</div>
                <Player width={window.screen.width*0.75} fluid={false} >
                    <source src="./images/final.mp4"  />
                    <BigPlayButton position="center" />
                </Player>
        </div>
    )
}
