import React from 'react'
import { JudgeCard } from './JudgeCard'
export const Speakers = () => {
    const speakers = [
        {
            "name": 'Shri Ashok Juneja',
            "photo": "./images/speakers/ashokjunejafinal.png",
            "position": "DGP Chhattisgarh Police",
            "css":"object-top"
        },
        {
            "name": 'Dr. Pradeep K. Sinha',
            "photo": "./images/speakers/pradeepsinhafinal.png",
            "position": "Director , IIIT-NR",
            "css":"object-top"
        },
        {
            "name": 'Shri Pradeep Gupta - IPS',
            "photo": "./images/jury/pradeepgupta.jpg",
            "position": "ADGP (Technical Services)",
            "css":"object-top"
        },

    ]
    return (
        <div className='container mx-2 md:mx-auto w-full md:w-11/12 bg-white'>
            <div className='text-4xl text-[#D00202] text-center font-bold text-opacity-95'>Keynote Speakers</div>
            <div className='grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 pt-12 place-content-center place-items-center gap-2 lg:px-24 xl:px-32'>
                {speakers.map((speaker)=>{
                    return(<JudgeCard judge={speaker} />)
                })}
            </div>
        </div>
    )
}
