import React from "react";
import "./style/Schedule.css";

export const Timeline = () => {




  return (
    <>
      <div className="pt-14 flex flex-col justify-center items-center text-[#1E3D7D]" id="timeline">

        <div className="container text-4xl text-[#D00202] py-3 text-center bg-white font-bold text-opacity-95 md:pb-10">
          <h2 className="logoandNAME">Timeline</h2>
        </div>

        {/* Timeline for Screens greater than lg*/}

        <div className="hidden lg:flex lg:flex-row pb-14">
          <p className="pr-20 text-2xl font-medium">Start of course</p>
          <a id="dash" href="./" className="opacity-50 text-2xl block">
            ○- - - - - - - - - - - - - - - - - -◉
          </a>

          <p className="pl-20 text-2xl font-medium">End of course</p>

        </div>


        <div className="pb-14 hidden lg:flex lg:flex-row md:space-x-10 lg:space-x-20">

          <div className="datecontainer flex justify-center items-center">
            <p className="text-[26px] antialiased font-bold tracking-widest whitespace-pre">
              2022   /   06  /   13
            </p>
          </div>


          <div className="datecontainer flex justify-center items-center">
            <p className="text-[26px] antialiased font-bold tracking-widest whitespace-pre">
              2022   /   06  /   29
            </p>
          </div>
        </div>
        {/* Timeline for Smaller Screens*/}

        <div className="lg:hidden flex flex-row w-full sm:w-11/12 justify-center space-x-2 xs:space-x-5 sm:space-x-20">
          <div className="grid rows-2 items-center">
            <div className="pt-3">
              <div className="hidden space-y-3 items-center xs:flex xs:flex-col">
                <div>○</div>
                <div>|</div>
                <div>|</div>
                <div>|</div>
                <div>|</div>
                <div>|</div>
                <div>|</div>

              </div>
            </div>
            <div className="hidden xs:block">◉</div>
          </div>
          <div className="grid grid-rows-2  space-y-5 items-center">
            <div className="flex flex-col items-center space-y-5">
              <div className="text-2xl font-medium">Start of course</div>

              <div className="datecontainer-md h-20 w-fit p-2 bg-white flex justify-center items-center">

                <p className="text-[26px] antialiased font-bold tracking-widest  whitespace-pre">
                  2022   /   06  /   13
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center space-y-5">
              <div className="text-2xl font-medium">End of course</div>

              <div className="datecontainer-md h-20 w-fit p-2 bg-white flex justify-center items-center">

                <p className="text-[26px] antialiased font-bold tracking-widest  whitespace-pre">
                  2022   /   06  /   29
                </p>
              </div>
            </div>

          </div>
        </div>

        {/* Register Section for Screens greater than lg*/}

        {/* <div className=" pb-14 hidden lg:flex lg:flex-row justify-center items-center w-[800px]  ">

          <div className='w-40 justify-center items-center content-center flex pl-[60px]'>
            <div className="flex h-10 w-10 border rounded-xl justify-center items-center bg-[#1E3D7D] ">
              <p className="text-white text-xl">
                0
              </p>
            </div>
          </div>

          <div className='w-60 justify-center items-center content-center flex text-2xl font-normal'>
            Days left to sign up
          </div>

          <div className='w-40 justify-center items-center content-center flex pl-[50px]'>
            <a href="https://hackmanthan.devfolio.co/" target='_blank' rel="noreferrer" className='bg-[#F46632] text-white w-fit p-3 px-12 rounded-xl cursor-pointer'>
              Register
            </a>
          </div>
        </div>

        <div className="flex flex-row lg:hidden justify-between items-center space-x-5 py-10">
          <div className="flex h-10 w-10 border rounded-md p-1 xs:p-0 xs:rounded-xl justify-center items-center bg-[#1E3D7D]">
            <p className="text-white text-xl ">
            0
            </p>
          </div>
          <div className="text-md sm:text-2xl">
            Days left to sign up
          </div>
          <a href="https://hackmanthan.devfolio.co/" target='_blank' rel="noreferrer" className="bg-[#F46632] rounded-xl px-9 py-3 cursor-pointer">
            Register
          </a>
        </div> */}


      </div>
    </>
  );
};