import React from 'react'
export const JudgeCard = (props) => {

    
    return (
        <div className='flex flex-col items-center space-y-2'>
            <div className=''>
            <img src={props.judge.photo}  className={`border border-black rounded-xl h-60 w-60 aspect-auto object-cover ${props.judge.css} drop-shadow-lg`} alt='Judge'/>
            </div>
            <div className='text-md text-[#1C3568] text-center font-bold tracking-wide pb-0.5 whitespace-pre'>{props.judge.name}</div>
            <div className='text-md text-[#1C3568] text-center font-semibold tracking-tight whitespace-pre'>{props.judge.position}</div>
        </div>
    )
}
