import React from 'react'

export const Hero = () => {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://apply.devfolio.co/v2/sdk.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div className='' style={{
            backgroundImage: `url("./images/newPhoto.jpg")`,
            backgroundSize: 'cover',
            width: '100%'
        }}>
            <div className='backdrop-filter backdrop-blur-[8px] w-full h-[510px] flex flex-col md:flex-row justify-center items-center  space-y-3 md:space-y-0 md:space-x-4 lg:space-x-20 text-white px-10'>
            <div className='w-80 h-48 md:w-96 md:h-52 pt-5 '>
                <img src="./images/NewLogo.png" alt="" className='object-cover' />
            </div>
            <div className='flex flex-col space-y-3 md:space-y-10 text-center items-center'>
                <div className='text-2xl md:text-4xl font-semibold'>Chhattisgarh Police in Collaboration<br className='sm:hidden'></br> with IIIT Naya Raipur Presents</div>
                <h1 className='text-3xl md:text-5xl font-bold'>HackManthan : Hackathon</h1>
                <div>
                    <div
                        className="apply-button"
                        data-hackathon-slug="hackmanthan"
                        data-button-theme="dark-inverted"
                        style={{ height: "44px", width: "312px" }}
                    ></div>
                </div>
            </div>
        </div>
        </div>
    )
}
