import React from "react";
import Link from "react-scroll/modules/components/Link";
import { Sidebar } from "./Sidebar";

export const Navbar = () => {
  return (
    <div>
      <div className="hidden md:block px-4 lg:px-10 xl:px-40 py-6 bg-[#3C71DC] text-white">
        <div className="flex flex-row justify-between items-center space-x-1">
          <div className="text-2xl flex flex-row items-center space-x-2">
          <div>
            <img
              src="./images/cgpolice.png"
              alt="CG Police Logo"
              className="m-h-32 m-w-28 max-h-32 max-w-28"
            />
          </div>
            {/* <div>
                  SANRAKSHIT
                  </div> */}
          </div>
          <div className=" flex-row space-x-5 lg:space-x-14 items-center flex">
            <Link to='ProblemStatement' smooth={true} duration={1000} className="whitespace-pre md:text-lg lg:text-xl text-md cursor-pointer hover:text-[#FFA026] transition-colors duration-200 ">
              Home
            </Link>
            <Link to='About' smooth={true} duration={1000} className="md:text-lg lg:text-xl text-md cursor-pointer hover:text-[#FFA026] transition-colors duration-200 ">
              About
            </Link>
            <Link to='ProblemStatement' smooth={true} duration={1000}  className="md:text-lg lg:text-xl text-md cursor-pointer hover:text-[#FFA026] transition-colors duration-200 ">
              Problem Statements
            </Link>
            <Link to='Schedule' smooth={true} duration={1000}  className="md:text-lg lg:text-xl text-md cursor-pointer hover:text-[#FFA026] transition-colors duration-200 ">
              Schedule
            </Link>
            <Link to='Jury' smooth={true} duration={1000}  className="md:text-lg lg:text-xl text-md cursor-pointer hover:text-[#FFA026] transition-colors duration-200 ">
              Jury
            </Link>
            <Link to='Contact' smooth={true} duration={1000} className="md:text-lg lg:text-xl text-md cursor-pointer hover:text-[#FFA026] transition-colors duration-200 ">
              Contact
            </Link>
          </div>
          <div>
              <img
                src="./images/iiitlogo.png"
                alt=""
                className="m-h-28 m-w-28 max-h-28 max-w-28"
              />
            </div>
        </div>
      </div>
      <div className="md:hidden">
        <Sidebar />
      </div>
    </div>
  );
};
