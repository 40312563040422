import 'video-react/dist/video-react.css';
import { About } from "./Components/About";
import { Foot } from "./Components/Foot";
import { Timeline } from "./Components/Timeline";
import { Judges } from "./Components/Judges";
import { ProblemStatements } from "./Components/ProblemStatements";
import { Evaluation } from "./Components/Evaluation";
import { GetUpdates } from "./Components/GetUpdates";
import { Prizes } from "./Components/Prizes";
import { Schedule } from "./Components/Schedule";
import { Hero } from "./Components/Hero";
import { Clock } from "./Components/Clock";
import { Speakers } from "./Components/Speakers";
import { Navbar } from "./Components/Navbar";
import { Navbar1 } from "./Components/Navbar1";
import { Loader } from "./Components/Loader";
import { useEffect, useState } from "react";
import { Sponsors } from "./Components/Sponsors";
import { Schedule1 } from "./Components/Schedule1";
import { OpeningModal } from "./Components/OpeningModal";
import { Video } from "./Components/Video";
import { Generalcarousel } from './Components/Generalcarousel';
import { Winners } from './Components/Winners';

function App() {
  const [loading, setLoading] = useState(true);
  const [value, setvalue] = useState(10);

  useEffect(() => {
    setTimeout(() => {
      setvalue(40);
    }, 200);
    setTimeout(() => {
      setvalue(60);
    }, 200);
    setTimeout(() => {
      setvalue(80);
    }, 200);

    document.addEventListener("DOMContentLoaded", loaded());
    function loaded() {
      setTimeout(() => {
        setvalue(100);
      }, 750);
      setTimeout(() => {
        setLoading(false);
      }, 1250);
    }
  }, []);


  // const [showModal, setShowModal] = useState(true);

  // const togglModal=(val)=>{
  //   setShowModal(val);
  // }
  
  // useEffect(()=>{
  //   if(showModal){
  //     document.querySelector("body").style.overflow = 'hidden';
  //   }
  //   else{
  //     document.querySelector("body").style.overflow = 'visible';
  //   }
  // }, [showModal])

  // document.body.addEventListener("keydown",(e)=>{
  //   if(e.keyCode === 27){
  //     togglModal(false);
  //   }
  // })


  return (
    <>
      {loading ? (
        <Loader value={value} />
      ) : (
         <div className={`${loading?'hidden':''}`}>

        {/* <div id="modalSection">      

          {showModal && <OpeningModal ChangeModal={togglModal}/>}


        </div> */}


        <div className=''><Navbar /></div>
         {/* <Navbar1/> */}
         <div className='flex flex-col space-y-12 sm:space-y-14 md:space-y-16 lg:space-y-24 bg-white select-auto'>
           <div className='px-0'><Hero /></div>

           {/* <div className='px-2 mr-2'><Clock /></div> */}
           <div className='px-0 mr-2 flex justify-center items-center' id='about'><About /></div>
           <div className='px-2 mr-2'><Speakers /></div>
           <div className='px-2 mr-2'><Video /></div>
           <div className='px-2 mr-2'><Generalcarousel /></div>
           <div className='px-2 mr-2'><Winners /></div>
           <div className='px-2 mr-2'><ProblemStatements /></div>
           <div className='px-2' id='schedule'><Schedule1 /></div>
           <div className='px-2 mr-2'><Judges /></div>
           <div className='px-2 mr-2'><Prizes /></div>
           <div><GetUpdates/></div>
           {/* <div className='px-2 mr-2'><Evaluation /></div> */}
           <div className='px-2 mr-2' id='sponsors'><Sponsors /></div>
           <div className='px-2 mr-2'><Timeline/></div>
           <div className='px-2 mr-2'><Foot/></div>
         </div>

         </div>
      )}
    </>
  );
}

export default App;
