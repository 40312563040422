import React from "react";
import { MdDone } from "react-icons/md";
import { BiLoader } from "react-icons/bi";
export const GetUpdates = () => {
  const [email, setEmail] = React.useState("");
  const [status, setStatus] = React.useState("");
  function handlechange(e) {
    setEmail(e.target.value);
  }
  const adduser = async (event) => {
    event.preventDefault();

    const { REACT_APP_KEY } = process.env;
    setEmail("");
    setStatus("Processing...");
    const response = await fetch("https://hackmanthan.herokuapp.com/addEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        key: REACT_APP_KEY,
      }),
    });
    const data = await response.json();
    if (data.status === "saved") {
      setStatus(
        "Your email has been successfully registered, Headlines will be sent to you soon."
      );
    } else if (data.status === "exists") {
      setStatus("Already exists");
    } else {
      setStatus("Error");
    }
    setTimeout(() => {
      setStatus("");
    }, 12000);
  };

  return (
    <div className="pt-[50px]">
      <div className="h-fit flex bg-[#1E3D7D] flex-row justify-center items-center space-x-4 text-[#F8F8F8] px-5">
        <div className="h-[400px] w-fit md:w-9/12 lg:w-5/12 flex justify-center items-center">
          <div className="flex flex-col">
            <div className="pb-[30px] text-xl sm:text-2xl md:text-4xl">
              Request a list of headings
            </div>

            <div className="pb-[45px] text-sm sm:text-md md:text-lg">
              Please enter your email address to receive the regular updates
            </div> 


            <form onSubmit={adduser}>
            <div className="pb-[30px] flex sm:flex-row flex-col space-x-2">
              <input
                typeof="email"
                placeholder="Email Address"
                value={email}
                onChange={handlechange}
                className="bg-white bg-opacity-20 rounded-lg  px-4 h-[35px] sm:w-[60%] w-[100%] "
                type="email"
                id="fname"
                name="fname"
              />
              <button
                type="submit"                
                className="bg-orange-600 text-white sm:w-[140px] w-[140px] sm:mt-0 mt-4  h-fit flex justify-center items-center rounded-xl p-2"
              >
                <div>Get Updates</div>
              </button>
            </div>
            </form>



            <div className="flex flex-row justify-center items-center space-x-3">
              <div className="w-[20px] h-[20px]">
                <BiLoader
                  className={`text-white ${
                    status === "Processing..." ? "visible" : "hidden"
                  }`}
                />
                <MdDone
                  className={`text-green-600 ${
                    status ===
                    "Your email has been successfully registered, Headlines will be sent to you soon."
                      ? "visible"
                      : "hidden"
                  }`}
                />
              </div>
              <p className="text-[0.75rem] sm:text-sm text-[#ACACAC]">
                {status}
              </p>
            </div>
          </div>
        </div>

        <div className="h-[400px] w-fit md:w-9/12 lg:w-5/12 flex justify-center items-center">
          <img src="./images/mail.svg" alt="Email SVG" />
        </div>
      </div>
    </div>
  );
};
