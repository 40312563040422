import React, { useState } from "react";
import { Link } from "react-scroll";
// 0 - Hidden , 1- Translate-x-0, 2-Translate-x-full
import { Fade as Hamburger } from "hamburger-react";
export const Sidebar = () => {
  const [isOpen, setOpen] = useState(false);

  document.addEventListener("scroll", (e) => {
    if (isOpen) {
      setOpen(false);
    }
  });

  return (
    <div>
      <div className="bg-[#3C71DC] text-white flex flex-row justify-between items-center relative px-3 py-2">
        <div className="flex flex-row items-center space-x-2">
          <div>
            <img
              src="./images/iiitlogo.png"
              alt="Logo"
              className="min-h-[85px] min-w-[85px] max-h-[85px] max-w-[85px]"
            />
          </div>
        </div>
        <div>

          <img
            src="./images/cgpolice.png"
            alt="Logo"
            className="m-h-20 m-w-20 max-h-24 max-w-20"
          />
        </div>
        <div className="w-[90px] h-[80px] flex justify-center items-center">
          <Hamburger
            size={36}
            duration={0.8}
            rounded
            toggled={isOpen}
            toggle={setOpen}
            label="Show Menu"
          />
        </div>
      </div>
      <div className="">
        <div
          className={`z-40 top-28 right-0 flex flex-col h-full bg-[#3C71DC] text-white w-48 transition-transform duration-[800ms] ease-in-out fixed ${isOpen ? "translate-x-0" : "translate-x-full"
            }`}
        >
          <Link
            to="About"
            smooth={true}
            duration={1000}
            className=" border-white px-2 py-2"
            onClick={() => {
              setOpen(false);
            }}
          >
            About
          </Link>
          <Link
            to="ProblemStatement"
            smooth={true}
            duration={1000}
            className="border-t border-white px-2 py-2"
            onClick={() => {
              setOpen(false);
            }}
          >
            Problem Statements
          </Link>
          <Link
            to="Schedule"
            smooth={true}
            duration={1000}
            className="border-t border-white px-2 py-2"
            onClick={() => {
              setOpen(false);
            }}
          >
            Schedule
          </Link>

          {/* <div className='border-t border-b border-white px-2 py-2'>Prizes</div> */}
          <Link
            to="Contact"
            smooth={true}
            duration={1000}
            className="border-t border-b border-white px-2 py-2 cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            Contact
          </Link>
          {/* <a href="https://hackmanthan.devfolio.co/" target='_blank' rel="noreferrer"
            className="border-t border-b border-white px-2 py-2 cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            Register
          </a> */}
        </div>
      </div>
      <div className="translate-x-full hidden"></div>
      <div className="translate-x-0  hidden"></div>
    </div>
  );
};
