import React from "react";
import { useState } from "react";
import "./style/Clock.css";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
export const Clock = () => {
  //   const endtime = new Date("2022-06-13T00:00:00+00:00");
  const endtime = new Date("2022-06-13T00:00:00+05:30");

  const [time, setTime] = useState(["00", "00", "00", "00"]);

  setInterval(() => {
    const t = endtime.getTime() - Date.now();
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((t % (1000 * 60)) / 1000);
    setTime([days, hours, minutes, seconds]);
  }, 1000);

  return (
    <div className="w-full flex flex-col justify-center items-center space-y-2">

      <div className="text-2xl md:text-4xl  text-[#D00202] font-bold text-center text-opacity-95 pb-10">
        Time Remaining for registration
      </div>


      <div className="w-full grid grid-cols-2 gap-y-4 lg:gap-y-0 lg:space-y-0 lg:flex lg:flex-row space-x-2 lg:space-x-6 xl:space-x-10 justify-center items-center ">


        {/*         
        <div className="flex flex-row items-center justify-center space-x-3 xl:space-x-5">
          <div className="flex justify-center items-center flex-col space-y-2 xl:space-y-4 my-5">
            <div className="flex flex-row justify-center items-center space-x-2">
              <div className="h-[2px] w-10 bg-black"></div>
              <div className="TimeTitle">Days</div>
              <div className="h-[2px] w-10 bg-black"></div>
            </div>
            <div className="TimeCard ">
              <div className="Days text-white text-[40px]">{time[0]}</div>
            </div>
          </div>
          <div className="text-[100px] font-thin hidden lg:block">:</div>
        </div>




        <div className="flex flex-row items-center justify-center space-x-3 xl:space-x-5">
          <div className="flex justify-center items-center flex-col space-y-2  xl:space-y-4">
            <div className="flex flex-row justify-center items-center space-x-2">
              <div className="h-[2px] w-10 bg-black"></div>
              <div className="TimeTitle">Hours</div>
              <div className="h-[2px] w-10 bg-black"></div>
            </div>
            <div className="TimeCard ">
              <div className="Days text-white text-[40px]">{time[1]}</div>
            </div>
          </div>
          <div className="text-[100px] font-thin hidden lg:block">:</div>
        </div>




        <div className="flex flex-row items-center justify-center space-x-3 xl:space-x-5">
          <div className="flex justify-center items-center flex-col space-y-2  xl:space-y-4">
            <div className="flex flex-row justify-center items-center space-x-2">
              <div className="h-[2.5px] xs:h-[2px] w-10 bg-black"></div>
              <div className="TimeTitle">Minutes</div>
              <div className="h-[2.5px] xs:h-[2px] w-10 bg-black"></div>
            </div>
            <div className="TimeCard ">
              <div className="Days text-white text-[40px]">{time[2]}</div>
            </div>
          </div>
          <div className="text-[100px] font-thin hidden lg:block">:</div>
        </div>



        <div className="flex flex-row items-center justify-center space-x-3 xl:space-x-5">
          <div className="flex justify-center items-center flex-col space-y-2  xl:space-y-4">
            <div className="flex flex-row justify-center items-center space-x-2">
              <div className="h-[2.5px] xs:h-[2px] w-10 bg-black"></div>
              <div className="TimeTitle">Seconds</div>
              <div className="h-[2.5px] xs:h-[2px] w-10 bg-black"></div>
            </div>
            <div className="TimeCard ">
              <div className="Days text-white text-[40px]">{time[3]}</div>
            </div>
          </div>
        </div> */}


        <div className="w-48">
          <CircularProgressbarWithChildren value={time[0]} strokeWidth={3} maxValue={time[0] + (time[0]%10)} styles={{
            root: {},
            path: {
              stroke: `rgb(28, 53, 104)`,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease 0s',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#d6d6d6',
              strokeLinecap: 'round',
              
            },
            text: {
              fill: '#f88',
              fontSize: '16px',
            },
            
            background: {
              fill: '#3e98c7',
            },
          }}>
            <div className="flex flex-col space-y-2 items-center justify-center">
              <div className="text-6xl font-semibold">{time[0]}</div>
              <div className="text-lg">Days</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>


        <div className="w-48">
          <CircularProgressbarWithChildren value={time[1]} strokeWidth={3} maxValue={24} styles={{
            root: {},
            path: {
              stroke: `rgb(28, 53, 104)`,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease 0s',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#d6d6d6',
              strokeLinecap: 'round',
              
            },
            text: {
              fill: '#f88',
              fontSize: '16px',
            },
            
            background: {
              fill: '#3e98c7',
            },
          }}>
            <div className="flex flex-col space-y-2 items-center justify-center">
              <div className="text-6xl font-semibold">{time[1]}</div>
              <div className="text-lg">Hours</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>

        <div className="w-48">
          <CircularProgressbarWithChildren value={time[2]} strokeWidth={3} maxValue={60} styles={{
            root: {},
            path: {
              stroke: `rgb(28, 53, 104)`,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease 0s',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#d6d6d6',
              strokeLinecap: 'round',
              
            },
            text: {
              fill: '#f88',
              fontSize: '16px',
            },
            
            background: {
              fill: '#3e98c7',
            },
          }}>
            <div className="flex flex-col space-y-2 items-center justify-center">
              <div className="text-6xl font-semibold">{time[2]}</div>
              <div className="text-lg">Minutes</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>

        <div className="w-48">
          <CircularProgressbarWithChildren value={time[3]} strokeWidth={3} maxValue={60} styles={{
            root: {},
            path: {
              stroke: `rgb(28, 53, 104)`,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease 0s',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#d6d6d6',
              strokeLinecap: 'round',
              
            },
            text: {
              fill: '#f88',
              fontSize: '16px',
            },
            
            background: {
              fill: '#3e98c7',
            },
          }}>
            <div className="flex flex-col space-y-2 items-center justify-center">
              <div className="text-6xl font-semibold">{time[3]}</div>
              <div className="text-lg">Seconds</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>


      </div>
    </div>
  );
};
