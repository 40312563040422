import React from "react";
export const About = () => {
  return (
    <div
      id="About"
      className="container mx-2 md:mx-auto w-full md:w-11/12 bg-white"
    >
      <div className="py-5 md:py-20 flex flex-col justify-center items-center space-y-10">
        <div className="flex flex-col space-y-5 justify-center items-center">
          <div className="text-2xl md:text-4xl text-[#D00202] font-bold text-center text-opacity-85">
            Chhattisgarh Police
          </div>
          <div className="lg:w-9/12  w-11/12 text-xl text-justify text-[#1C3568]">
          The inception of the Chhattisgarh Police goes back to the year 2000 when the new state of Chhattisgarh emerged on the map of India. For the last two decades, policing was focused mainly on tackling the challenge posed by left-wing extremism in the difficult terrains of the state. However, as a result of continued development activities and Anti-Naxal operations, Chhattisgarh police has been able to successfully curb the menace of the Naxal problem and the left-wing extremism is now confined to very limited pockets of the region. This has given the Police a much-needed time and space to look ahead in handling the new emerging challenges in policing driven mainly by the advancement in technology. A strong need was felt for a long time to include the tech-savvy young and bright minds of our nation to solve the technology-related problems faced by Chhattisgarh Police. This have given birth to the idea of HackManthan, an opportunity as well as platform where like-minded students may meet, grow, innovate and produce solutions for a secure future.

          </div>
        </div>
        <div className="flex flex-col space-y-5 justify-center items-center">
          <div className="text-2xl md:text-4xl text-[#D00202] font-bold text-center text-opacity-85">
            HackManthan : Hackathon
          </div>
          <div className="lg:w-9/12  w-11/12 text-xl text-justify text-[#1C3568]">
          The exponential growth in usage of information technology has led to emergence of cyber-crimes, which has allowed cyber criminals to experiment with new modus operandi. With vast amounts of information passing through internet pathways every moment, it has become necessary that we have robust measures to prevent undue incidents. Security of cyber space has a strong bearing on national security. Unlawful and unmonitored online activities, lack of observations about previous misdeeds and tedious manual work are issues we all have to suffer. Scam calls, data theft, and DDOS attacks are some of the common cyber-crime issues, that need more efficient handling. 

To give opportunities to young bright minds who wish to bring a positive change, Chhattisgarh Police, in collaboration with IIIT-Nawa Raipur, brings to you HACKMANTHAN : a week-long coding challenge that tests your creativity and your resolve to make internet a safer place. We present to you a platform to declare your new, big idea for changing “CyberSpace.” HackManthan is being conducted to promote the coding culture among students to curb the ever-increasing cybercrime menace and promote cyber harmony. This seven-day-long event will puzzle the students and enrich them with the experience of working in a team in a competitive atmosphere.

This event is being organized with committed efforts by the Chhattisgarh Police Department, working toward Internet Governance and CyberSecurity; it is aligned with the objectives of promoting a safe and law-abiding cyber atmosphere and prevention of cyber-crime. Adding value to the hackathon, the Police Department has conceptualized and identified real-life problem statements to bring about vigilant solutions.

          </div>
        </div>



        <div className="flex flex-col space-y-5 justify-center items-center">
          <div className="text-2xl md:text-4xl text-[#D00202] font-bold text-center text-opacity-85">
            IIIT Naya Raipur
          </div>
          <div className="lg:w-9/12 w-11/12 text-xl text-justify text-[#1C3568]">
            Dr. SPM IIIT-Naya Raipur, established by the International Institute of Information Technology University Act, 2013 of the Government of Chhattisgarh, is a joint venture by Chhattisgarh State Government and National Thermal Power Corporation (NTPC).
            The institute dedicates itself to achieving excellence in higher education, research, and development in Information Technology (IT) and related fields. It firmly believes in imparting knowledge and entrepreneurial skills to students to prepare them to address real-world problems. IIIT-NR promotes cutting-edge research and product development laboratories that conduct multidisciplinary research and product development to support this vision.

          </div>
        </div>
      </div>
    </div>
  );
};
