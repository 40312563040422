import React from "react";

export const Prizes = () => {
  return (
    <div className="container mx-2 md:mx-auto w-full md:w-11/12 bg-white">
      <div className="Card flex rounded-[30px] bg-white shadow-2xl drop-shadow-sm py-10 h-[80%] flex-col justify-center items-center xl:pr-10">
        <div className="pb-8 md:pb-[90px] px-[4.5rem] text-4xl text-[#D00202] font-bold text-opacity-95">
          <div className="logoandNAME">Prizes</div>
        </div>
        <div className="flex flex-col md:space-y-0 md:flex-row justify-between md:justify-center xl:justify-center items-center w-[100%] space-x-2 xl:space-x-8 pb-8 px-5 lg:px-10 xl:px-36">
          <div className="Details w-full xl:w-[90%] md:w-[65%] h-fit md:h-[300px] flex flex-col justify-center xl:px-[20px] pb-2 sm:pb-0 text-[#1C3568]">
            <div className="text-[20px] pb-8">
              What will be your achievements by participating in this Hackathon?
            </div>
            <ul className="pb-8 text-[16px] space-y-4 xl:whitespace-pre">
              <li>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    src="./images/ok.svg"
                    className="w-[27px] h-[27px]"
                    alt="Green Tick Logo"
                  />
                  <p>
                    Interact with fellow coders and gain insights on
                    methodologies used by others.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    src="./images/ok.svg"
                    className="w-[27px] h-[27px]"
                    alt="Green Tick Logo"
                  />
                  <p>
                    Get a chance to work on some of the newest and most engaging
                    problems in the cybersecurity field.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    src="./images/ok.svg"
                    className="w-[27px] h-[27px] "
                    alt="Green Tick Logo"
                  />
                  <p className="break-words w-[100%]">
                    Challenge yourself by crafting solutions for tricky and
                    engaging scenarios.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    src="./images/ok.svg"
                    className="w-[27px] h-[27px]"
                    alt="Green Tick Logo"
                  />
                  <p>
                    Win grand cash prizes and a meet-and-greet with the top
                    brass in the CG Police force.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    src="./images/ok.svg"
                    className="w-[27px] h-[27px]"
                    alt="Green Tick Logo"
                  />
                  <p>Certificates for All Participants.</p>
                </div>
              </li>
            </ul>
          </div>

          <div className="Prizes w-fit sm:w-[50%] lg:w-[30%] h-[280px] md:h-[300px] bg-[#1C3568] rounded-[28px] flex flex-col justify-center items-center text-white">
            <ul className="pb-8 text-[27px] space-y-2 whitespace-pre px-5 md:px-2 lg:px-8">
              <li>Rs. 80,000 I PRIZE</li>
              <li>Rs. 40,000 II PRIZE</li>
              <li>Rs. 20,000 III PRIZE</li>
            </ul>
            {/* <a
              href="https://hackmanthan.devfolio.co/"
              target="_blank"
              rel="noreferrer"
              className="bg-[#F36732] text-white flex justify-center items-center w-[140px] h-[40px] rounded-xl cursor-pointer"
            >
              Register
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};
