import React from "react";
import { FaFacebookSquare, FaLinkedin, FaInstagram, FaTwitter } from "react-icons/fa";

export const Foot = () => {
  return (
    <>
      <footer id="Contact" className="container px-6 mx-auto text-center bg-white py-8 justify-center w-full" >
        <div className="hidden md:flex md:flex-row justify-between items-center space-x-5 md:space-x-12">
          <div className="flex flex-row space-x-5">
            <a href='https://www.facebook.com/profile.php?id=100081570903064' rel="noreferrer" target='_blank'><FaFacebookSquare size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
            <a href='https://twitter.com/ManthanHack' rel="noreferrer" target='_blank'>< FaTwitter size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
            <a href='https://www.instagram.com/hackmanthan/' rel="noreferrer" target='_blank'><FaInstagram size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
            <a href='https://www.linkedin.com/company/hackmanthan/' rel="noreferrer" target='_blank'><FaLinkedin size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
          </div>
          <div className="">
            <img src="./images/logoloader.svg" className="h-32 w-40 hidden" alt="Logo of Hackmanthan" />
          </div>
          <div className="flex flex-col justify-start items-start">
            <a className="text-2xl tracking-wide" href="mailto:team@hackmanthan.com">
              Email : team@hackmanthan.com
            </a>
            <a className="text-2xl" href="tel:7000216910">
              Contact Number:+91 7000216910
            </a>
          </div>
        </div>

        <div className="md:hidden flex flex-col justify-center items-center space-y-5 ">
          <div className="">
            <img src="./images/logoloader.svg" className="h-32 w-40 hidden" alt="Logo of Hackmanthan" />
          </div>
          <div className="flex flex-row space-x-5">
            <a href='https://www.facebook.com/profile.php?id=100081570903064' rel="noreferrer" target='_blank'><FaFacebookSquare size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
            <a href='https://twitter.com/ManthanHack' rel="noreferrer" target="_blank"><FaTwitter size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
            <a href='https://www.instagram.com/hackmanthan/' rel="noreferrer" target="_blank"><FaInstagram size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
            <a href='https://www.linkedin.com/company/hackmanthan/' rel="noreferrer" target="_blank">< FaLinkedin size={40} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
          </div>

          <div className="flex flex-col justify-start items-start">
            <a className="text-2xl tracking-wide" href="mailto:team@hackmanthan.com">
              Email : team@hackmanthan.com
            </a>
            <a className="text-2xl" href="tel:7000216910">
              Contact Number:+91 7000216910
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};
