import React from 'react'
import { JudgeCard } from './JudgeCard'

export const Judges = () => {
    const judges = [
        {
            "name": 'Shri Pradeep Gupta - IPS',
            "photo": "./images/jury/pradeepgupta.jpg",
            "position": "ADGP (Technical Services)",
            "css":"object-top"
        },
            // {
            //     "name": 'Shri Mayank Shrivastava - IPS',
            //     "photo": "./images/jury/place.png",
            //     "position": "Director (Home Guards)",
            //     "css":"object-center"
            // },
        {
            "name": 'Shri Kavi Gupta',
            "photo": "./images/jury/kavigupta.png",
            "position": "Additional SP (Cyber), PHQ",
            "css":"object-center"
        },
        {
            "name": 'Shri Gaurav Rai - IPS',
            "photo": "./images/jury/gauravraifinal.png",
            "position": "CSP, Rajnandgaon ",
            "css":"object-top"
        },
        {
            "name": 'Smt. Ratna Singh - IPS',
            "photo": "./images/jury/ratnasingh-1.png",
            "position": "CSP, Raipur",
            "css":"object-top"
        },
        {
            "name": 'Dr. Venkanna U',
            "photo": "./images/jury/venkannaufinal.png",
            "position": "Assistant Professor (CSE),IIIT-NR",
            "css":"object-center"
        },
        {
            "name": 'Dr. Debanjan Das',
            "photo": "./images/jury/debanjandasfinal.png",
            "position": "Assistant Professor (ECE),IIIT-NR",
            "css":"object-center"
        },
        {
            "name": 'Dr. Srinivasa K G',
            "photo": "./images/jury/srinivasafinal.png",
            "position": "Professor (DSAI),IIIT-NR",
            "css":"object-center"
        },
        {
            "name": 'Dr. Rajesh Ingle',
            "photo": "./images/jury/rajeshinglefinal.png",
            "position": "Professor (CSE),IIIT-NR",
            "css":"object-center"
        },

    ]
    return (
        <div id='Jury' className='container mx-2 md:mx-auto w-full md:w-11/12 border shadow-2xl  rounded-lg lg:px-16 xl:px-24 bg-white'>
            <div className='py-5 flex flex-col justify-center items-center'>

                <div className='flex flex-col justify-center items-center py-10 my-10'>
                    <div className=''>
                        <div className="md:px-16 text-2xl md:text-4xl text-[#D00202] text-opacity-95 font-bold">
                            Distinguished Jury
                        </div>
                    </div>
                    <div className='px-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-12 pb-4 gap-y-12 sm:gap-8 lg:gap-12 xl:gap-16 place-content-evenly place-items-center'>
                        {judges.map((judge) => {
                            return (<JudgeCard judge={judge} key={judge.name} />)
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
