import React, { useState } from "react";
import "./style/problemstatement.css";
export const ProblemStatements = () => {
  const [Statement, setStatement] = useState(0);

  const onclick = (number) => {
    setStatement(number);
  };

  return (
    <div
      id="ProblemStatement"
      className="container pb-14 mx-2 md:mx-auto w-full md:w-11/12 border shadow-2xl rounded-xl lg:px-16 xl:px-24 bg-white"
    >
      <div className="py-5 md:py-20 flex flex-col justify-center items-center">
        <div className="md:px-12 md:text-[45px] font-bold underline text-2xl text-[#D00202]">
          Problem Statements
        </div>
      </div>

      <div>
        <div
          className={`flex flex-row justify-center items-center w-full md:w-11/12 lg:w-11/12 xl:w-9/12 container  px-2 sm:mx-auto py-2 md:py-0`}
        >
          <div
            className={`p-1 text-center  sm:p-[0.2rem] xl:p-3 cursor-pointer text-sm md:text-xl lg:whitespace-pre  ${
              Statement === 0
                ? "bg-[#1C3568] text-white rounded-l-xl border border-[#1C3568]"
                : "text-[#1C3568] bg-white rounded-l-xl border border-[#1C3568]"
            }`}
            onClick={() => {
              onclick(0);
            }}
          >
            <div className="smScreen ">1</div>
            <div className="bgScreen">Problem Statement 1</div>
          </div>
          <div
            className={`p-1 text-center  sm:p-[0.2rem] xl:p-3 cursor-pointer text-sm md:text-xl lg:whitespace-pre border-x  ${
              Statement === 1
                ? "bg-[#1C3568] text-white  border border-[#1C3568]"
                : "text-[#1C3568] bg-white border-y border-[#1C3568]"
            }`}
            onClick={() => {
              onclick(1);
            }}
          >
            <div className="smScreen">2</div>
            <div className="bgScreen">Problem Statement 2</div>
          </div>
          <div
            className={`p-1 text-center  sm:p-[0.2rem] xl:p-3 cursor-pointer text-sm md:text-xl lg:whitespace-pre border-x  ${
              Statement === 2
                ? "bg-[#1C3568] text-white  border border-[#1C3568]"
                : "text-[#1C3568] bg-white border-y border-[#1C3568]"
            }`}
            onClick={() => {
              onclick(2);
            }}
          >
            <div className="smScreen">3</div>
            <div className="bgScreen">Problem Statement 3</div>
          </div>
          <div
            className={`p-1 text-center  sm:p-[0.2rem] xl:p-3 cursor-pointer text-sm md:text-xl lg:whitespace-pre border-x  ${
              Statement === 3
                ? "bg-[#1C3568] text-white  border border-[#1C3568]"
                : "text-[#1C3568] bg-white border-y border-[#1C3568]"
            }`}
            onClick={() => {
              onclick(3);
            }}
          >
            <div className="smScreen">4</div>
            <div className="bgScreen">Problem Statement 4</div>
          </div>
          <div
            className={`p-1 text-center  sm:p-[0.2rem] xl:p-3 cursor-pointer text-sm md:text-xl lg:whitespace-pre  ${
              Statement === 4
                ? "bg-[#1C3568] text-white rounded-r-xl border border-[#1C3568] "
                : "text-[#1C3568] bg-white border-y border-[#1C3568] border-r rounded-r-xl"
            }`}
            onClick={() => {
              onclick(4);
            }}
          >
            <div className="smScreen">5</div>
            <div className="bgScreen">Problem Statement 5</div>
          </div>
        </div>




        <div className="py-8 flex flex-col-reverse md:grid md:grid-cols-3 w-full mx-auto justify-center  gap-x-2">
          <div className="">
            <img
              src="./images/logo.jpg"
              alt="Hackmanthan SVG"
              className="aspect-auto"
            />
          </div>
          <div className="text-justify col-span-2 text-lg md:text-2xl text-[#1E3D7D] px-2 relative">
            
          <div id="HeadingforProb" className=" mb-[20px] font-bold text-[#D00202]">
            {Statement === 0 ? (
                <div className="text-center text-xl md:text-2xl lg:text-3xl font-bold">
                  Track 1: Blockchain
                </div>
            ) : Statement===1 ? (
                <div className="text-center text-xl md:text-2xl lg:text-3xl font-bold">
                  Track 2: Integration of CCTV Data
                  </div>
            ) : Statement===2 ? (
                <div className="text-center text-xl md:text-2xl lg:text-3xl font-bold">
                  Track 3: Speech Emotion Recognition
                  </div>
            ) : Statement===3 ? (
                <div className="text-center text-xl md:text-2xl lg:text-3xl font-bold">
                  Track 4: Sentiment Analysis of social media posts
                  </div>
            ) : Statement===4 ? (
                <div className="text-center text-xl md:text-2xl lg:text-3xl font-bold">
                  Track 5: Crime Data Analysis
                  </div>
            ) : (
                <div className="text-center text-xl md:text-2xl lg:text-3xl font-bold">
                </div>
            )}
          </div>

            {Statement === 0 ? (
              <div>
                An interface/solution for crypto transaction data analysis.
                Crypto data comes from a variety of places, including wallets
                like metamask Coinbase, and exchanges like WazirX, and CoinDCX.
                It should aim to identify and analyze the interconnections of
                transactions to identify the details of the owner of crypto
                transactions.
              </div>
            ) : Statement === 1 ? (
              <div>
                Creating a centralized solution that will analyze the data from
                multiple sources. It should be able to detect a person’s face
                and vehicles in real-time and match it with the database
                automatically. Additionally detecting criminal activity/crime
                with CCTV by evaluating live films for violence, mob formation,
                questionable body language, etc.
              </div>
            ) : Statement === 2 ? (
              <div>
                <b>(Track 3.1)</b>The solution should evaluate the caller's
                voice on live ongoing calls that the caller is attending in the
                Emergency Response Support System. After studying the caller's
                voice, the solution should be able to forecast the caller's
                emotional/mental state. The solution should anticipate/suggest
                the following information about the caller: it may be a tense
                voice, a prank call, a drunk voice, an anguished voice, or any
                other condition which requires an immediate response.
                <br></br>
                <b>(Track 3.2)</b>On a daily basis, the Dial 112 System receives
                a large number of calls, with over 95 percent of them being
                blank, system-generated, or spoofing calls. The solution should
                prevent these non-productive calls on IVRS after identifying
                them.
              </div>
            ) : Statement === 3 ? (
              <div>
                The solution must be able to classify the sentiments that are
                expressed in the text source which is associated with that user
                on platforms such as Facebook, Instagram, and Twitter. It should
                accurately identify when the tone of the posts becomes
                inflammatory and hateful.
              </div>
            ) : (
              <div>
                Provide an Interface/Application that accepts police
                investigation data that examines and results in meaningful
                observations to extract knowledge from crime records to
                understand criminal behavior better and ultimately prevent
                future crimes.
              </div>
            )}
            {/* Button for learn more */}
            <div className="flex justify-center items-center absolute right-0 my-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/u/2/d/e/2PACX-1vQsu0b45-e6p3daJEQ1olUO1jKIdiNry-c05PEGqjsHSgYjDp2HgPmn6hbjr-zFZ3_-iXdr-BKuyapa/pub"
                className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded-xl"
              >
                Learn More
              </a>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
