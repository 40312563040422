import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination,Autoplay} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const Generalcarousel = () => {
    const Images=[
        "./images/EventImage2022/0 (3).jpeg",
        "./images/EventImage2022/0 (1).jpeg",
        "./images/EventImage2022/News1.jpg",
        "./images/EventImage2022/News2.jpg",
        "./images/EventImage2022/0 (2).jpeg",
        "./images/EventImage2022/0 (4).jpeg",
        "./images/EventImage2022/0 (5).jpeg",
        "./images/EventImage2022/0 (6).jpeg",
        "./images/EventImage2022/0 (7).jpeg",
        "./images/EventImage2022/0 (8).jpeg",
        "./images/EventImage2022/0 (9).jpeg",
        "./images/EventImage2022/0 (10).jpeg",
    ]







  return (
    <div className='space-y-10'>

        <div className='sm:text-4xl text-2xl font-bold underline text-[#D00202] justify-center items-center flex text-opacity-95 text-center'>
            <div className='md:w-[100%] w-[70vw]'>
                Glimpses of the Event 2022
            </div>
        </div>


        <Swiper
      // install Swiper modules
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      autoHeight={true}
      slidesPerView={1}
      spaceBetween={30}
      centeredSlides={true}
      loop={true}
      navigation={true}
      modules={[Pagination, Navigation, Autoplay]}
      className="mySwiper"
  >
        
        {Images.map((image,index)=>{
            return(
                <SwiperSlide key={index} className="justify-center items-center">
                    <div className='justify-center items-center flex'>
                        <img src={image} alt="" className='aspect-auto md:h-[70vh] md:w-[auto]'/>
                    </div>
                </SwiperSlide>
            )
        }
        )}
  </Swiper></div>
  )
}
