import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { FaCode, FaTrophy, FaUserCheck } from "react-icons/fa";
import { MdOutlineAppRegistration } from "react-icons/md";

import "react-vertical-timeline-component/style.min.css";
export const Schedule1 = () => {
  return (
    <div
      id="Schedule"
      className="opacity-100 container xs:mx-auto mr-2 px-0 lg:px-4 xl:px-32"
    >
      <div className="md:text-4xl text-2xl text-[#D00202] text-center pb-10 font-bold text-opacity-95">
        Schedule
      </div>

      <VerticalTimeline lineColor={"#1E3D7D"}>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(30, 61, 125)" }}
          date="27th May 2022 00:00"
          dateClassName={"opacity-100 text-white xl:text-black"}
          iconStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          icon={<MdOutlineAppRegistration size={35} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl">
            Registration Begins
          </h3>
          <p className="text-lg">
            Register for the contest with your team at Devfolio Platform. FIll
            out the forms carefully and precisely. Be sure to highlight your
            relevant experience in the field!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(30, 61, 125)" }}
          date="12th June 2022 23:59"
          dateClassName={"opacity-100 text-white xl:text-black"}
          iconStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          icon={<MdOutlineAppRegistration size={35} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl">
            Registration Ends
          </h3>
          <p className="text-lg">
            Your last chance to make yourself a part of this grand event. Make
            sure not to miss out!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(30, 61, 125)" }}
          date="13th June 2022 00:00"
          dateClassName={"opacity-100 text-white xl:text-black"}
          iconStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          icon={<FaCode size={35} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl">
            Coding period begins
          </h3>
          <p className="text-lg">
            Thus begins your solving time. You have been given a week to come up
            with the most watertight and innovative solutions to our problems.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(30, 61, 125)" }}
          date="20th June 2022 23:59"
          dateClassName={"opacity-100 text-white xl:text-black"}
          iconStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          icon={<FaCode size={35} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl">
            Coding period ends
          </h3>
          <p className="text-lg">
            Last date for the online submission of your solutions. Don’t forget
            to add everything!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(30, 61, 125)" }}
          date="23rd June 2022"
          dateClassName={"opacity-100 text-white xl:text-black"}
          iconStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          icon={<FaUserCheck size={35} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl">
            Shortlisting of the participants for final round
          </h3>
          <p className="text-lg">
            Based on the Submissions we will shortlist atmost 12 teams all over
            India and the list shortlisted teams would be released by 23rd June
            2022
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(30, 61, 125)" }}
          date="29th June 2022"
          dateClassName={"opacity-100 text-white xl:text-black"}
          iconStyle={{ background: "rgb(30, 61, 125)", color: "#fff" }}
          icon={<FaTrophy size={35} />}
        >
          <h3 className="vertical-timeline-element-title text-2xl">
            Final screening and Results
          </h3>
          <p className="text-lg">
            Shortlisted Teams would be presenting their work along with live
            demonstration to the Jury and winners would be declared on the same
            day.
            <br></br>
            <br></br>
            <div className="text-sm">
              <b>
                Terms and Conditions are subject to change according to
                organizers
              </b>{" "}
              <br></br>
              <span className="underline">
                The final rounds would be held offline and Travelling Allowance
                would be given to all qualified participants *
              </span>{" "}
              <br></br>
              <br />
              <a
                href="https://www.marriott.com/en-us/hotels/rprcy-courtyard-raipur/overview/"
                rel="noreferrer"
                target="_blank"
                className="font-extrabold text-[24px] text-yellow-300 underline"
              >
                Final round would be held at Marriott Hotel Raipur
              </a>
            </div>
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};
