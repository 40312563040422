import React from 'react'
import { SponsorCard } from './SponsorCard'

export const Sponsors = () => {

    const gold_sponsors = [
        // {
        //     "url":"https://devfolio.co/",
        //     "img":"./images/sponsors/sponsor1.svg",
        //     "sponsornumber":"Sponsor 4"
        // },
        
        {
            "url":"https://filecoin.io/",
            "img":"./images/sponsors/sponsor3.svg",
            "sponsornumber":"Sponsor 3",
            "css":"px-3 py-3",
            "parentcss":"py-3"
        },
        {
            "url":"https://celo.org/",
            "img":"./images/sponsors/sponsor4.svg",
            "sponsornumber":"Sponsor 4",
            "css":"px-3 py-3",
            "parentcss":"py-3"
        },
        // {
        //     "url":"https://tezos.com/",
        //     "img":"./images/sponsors/sponsor5.svg",
        //     "sponsornumber":"Sponsor 5",
        //     "css":"px-3 py-3",
        //     "parentcss":"py-3"
        // },
        
    ] 
    const premium_sponsors = [
        {
            "url":"https://www.tataprojects.com/",
            "img":"./images/sponsors/tataprojects.png",
            "sponsornumber":"Sponsor 2",
            "css":"scale-y-150 scale-x-90 px-3 py-3",
            "parentcss":"py-3"
        },
        {
            "url":"https://www.axisbank.com/",
            "img":"./images/sponsors/axis_bank@4x.png",
            "css":"rounded-xl xs:rounded-t-xl scale-y-105 object-center",
            "sponsornumber":"Sponsor 1",
            "parentcss":"mt-4 bg-[#871D42]"
        },
        {
            "url":"https://www.forensicsguru.com/",
            "img":"./images/sponsors/forensic-guru.jpg",
            "sponsornumber":"Sponsor 3",
            "css":"scale-x-90 px-3 py-3",
            "parentcss":"py-3"
            
        },
        {
            "url":"https://devfolio.co/",
            "img":"./images/sponsors/sponsor1.svg",
            "css":"px-3 py-3",
            "sponsornumber":"Sponsor 4",
            "parentcss":"py-3"
        },
        {
            "url":"https://polygon.technology/",
            "img":"./images/sponsors/sponsor2.svg",
            "sponsornumber":"Sponsor 2",
            "css":"px-3 py-3",
            "parentcss":"py-3"
        },
        
    ] 
  return (
    <div className='flex flex-col justify-center items-center'>
        <div className="container text-4xl text-[#D00202] py-3 text-center bg-white text-opacity-95 font-bold">
          <div className="logoandNAME">Sponsors</div>
        </div>
        <div className='text-2xl text-[#1C3568] pt-5 text-center bg-white text-opacity-95 font-bold'>Premium Sponsors</div>
        <div className='flex flex-col space-y-4 xs:space-y-0 xs:grid xs:grid-cols-2 xs:gap-x-10 xs:gap-y-4 lg:gap-x-0 lg:gap-y-0 lg:flex lg:flex-row lg:space-x-6 pt-10 px-10'>
            {premium_sponsors.map((sponsor)=>{
                return (<SponsorCard sponsor = {sponsor} key={sponsor.sponsornumber} />)
            })}
        </div>
        <div className='text-2xl text-[#1C3568] pt-14 text-center bg-white text-opacity-95 font-bold'>Gold Sponsors</div>
        <div className='flex flex-col space-y-4 xs:space-y-0 xs:grid xs:grid-cols-2 xs:gap-x-10 xs:gap-y-4 lg:gap-x-0 lg:gap-y-0 lg:flex lg:flex-row lg:space-x-6 pt-10 px-10'>
            {gold_sponsors.map((sponsor)=>{
                return (<SponsorCard sponsor = {sponsor} key={sponsor.sponsornumber} />)
            })}
        </div>
    </div>
  )
}
