import React from 'react'
import { FaGithub, FaLink} from "react-icons/fa";

export const Winners = () => {
  const winners=[
    {
      "Team": 'Third Eye',
      "photo": "./images/EventImage2022/W1.jpeg",
      "position": "1st",
      "github": "https://github.com/kartikbagri/Third-Eye",
      "link": "https://devfolio.co/projects/third-eye-a6ae",
      "TeamMember": [
        "Karik Bagri",
        "Vedansh Arun",
        "Rinav Gupta"
      ],
      "desc":"Third eye is a cutting-edge security protocol that uses deep learning and artificial intelligence to track motor vehicles via a CCTV network and Maps out their location on Mapbox API for easy tracking.  The police were only able to solve 19.6% of these instances using the presently available resources and technologies"
  },
  {
      "Team": 'Tatparya',
      "photo": "./images/EventImage2022/W2.jpeg",
      "position": "2nd",
      "github": "https://github.com/andrewflik/Tatparya-Frontend-V2",
      "link":"https://devfolio.co/projects/tatparya-a500",
      "TeamMember": [
        "Mohammad Tahauddin",
        "Devesh Rajput"
      ],
      "desc":"Tatparya is a social media sentiment analysis tool that classifies the sentiments that are expressed in the text source which is associated with that user on platforms such as Twitter, Facebook. It can identify when the tone of the posts becomes inflammatory and hateful. in Either Positive/ Negative or Netural 50%."
    },
    {
      "Team": 'Vaak Bhaavna',
      "photo": "./images/EventImage2022/W3.jpeg",
      "position": "3rd",
      "github": "https://github.com/Vinayak2002/Hackmanthan_VVS",
      "link":"https://devfolio.co/projects/vaak-bhaavna-8884",
      "TeamMember": [
        "Sankalp Varma",
        "Vinayak Shrivastava",
        "Vikalp kumar Tripathi"
      ],
      "desc":"Emotion recognition provides benefits to many institutions and aspects of life. It is useful and important for security and healthcare purposes. Also, it is crucial for easy and simple detection of human feelings at a specific moment without actually asking them."
    },
  ]


    
  return (
    <div>
        <div className='container mx-2 md:mx-auto w-full md:w-11/12 bg-white'>
            <div className='text-4xl text-[#D00202] text-center font-bold text-opacity-95 pb-4'>Winners of HackManthan 2022</div>
            <div className='flex md:flex-row flex-col items-start md:justify-center md:space-x-3 md:space-y-0 space-y-16'>
                {winners.map((winner)=>{
                    return <div className='md:py-8 py-6 md:px-8 px-4 border-1 shadow-2xl rounded-xl'>
                      <div className='flex justify-center flex-col md:w-[25vw] md:pb-0'>
                        <img src={winner.photo} className="w-[100%]" alt={winner.Team}/>

                        <div className='w-[100%] justify-center items-center flex'>
                          <p className='text-black font-semibold md:text-[2vw] text-[8vw] '>
                            {`${winner.position} Position`}
                          </p>
                        </div>
                        
                        <p className='text-black font-semibold md:text-[1.5vw] text-[5.5vw] '>
                            {`Project: ${winner.Team}`}
                        </p>

                        <div>
                          <p className='text-black font-semibold md:text-[1.2vw] text-[4.5vw] '>
                            {`By: ${winner.TeamMember.join(', ')}`}
                          </p>                          
                        </div>
                        <div>
                          <p className='text-black font-normal md:text-[1vw] text-[vw] '>
                            {winner.desc}
                          </p>                          
                        </div>
                      <p className='text-black font-semibold md:text-[1.5vw] text-[5vw] pt-2'>
                        Checkout Project
                      </p>
                    <div className='flex flex-row space-x-[20px] pt-1'>
                      <a href={winner.github} rel="noreferrer" target="_blank">< FaGithub size={30} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
                      <a href={winner.link} rel="noreferrer" target="_blank">< FaLink size={30} className="cursor-pointer hover:text-[#1C3568] transition-colors duration-500" /></a>
                    </div>
                    </div>
                    </div>
                })}
            </div>
        </div>
    </div>
  )
}
